import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	loaded: false,
};

const homeSlice = createSlice({
	name: 'home',
	initialState: initialState,
	reducers: {
		set: (state, action) => ({
			...state,
			...action.payload,
		}),
	},
});

export const { set } = homeSlice.actions;

export default homeSlice.reducer;
